// @flow
import React from "react";
import List from "../../page-components/collection/list";
import { Layout } from "../../components";

const Component = ({ ...rest }: any) => {
  const description =
    rest &&
    rest.location &&
    rest.location.state &&
    rest.location.state.description;

  return (
    <Layout>
      <List
        title={"Collection"}
        description={description || "All Collections"}
        {...rest}
      />
    </Layout>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
